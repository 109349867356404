import {simpleRateMultiplier, valueOrZero} from "../../utils";

let final_cost_form = $("#final-cost-summary-form")

if (final_cost_form.length) {

    let cases_recovered_all = $("#cases_recovered")
    let total_case_credit_title = $("#restaurant-title-cost")

    let cases_recovered_products = $(".credit-js.case_count")
    let price_per_case_products = $(".credit-js.price_per_case")
    let total_credit_products = $(".credit-js-total")

    function handle_case_credit_all(event) {
        let total = calculate_total_cost_all()
        total_case_credit_title.text(`Total Credit: $ ${total.toFixed(2)}`)
        return total
    }

    function calculate_total_cost_all() {
        let total = 0
        total_credit_products.each((index, element) => {
            let element_val = parseFloat(valueOrZero($(element).val()))
            total += element_val
        })
        return total
    }

    function handle_case_credit_products(event) {
        if(event === null) {
            cases_recovered_products.trigger("change")
            return
        }
        let product_item_id = $(event.target).data("product-id")
        let cases_on_hold_jq = cases_recovered_products.filter(`[data-product-id=${product_item_id}]`)
        let price_per_case_jq = price_per_case_products.filter(`[data-product-id=${product_item_id}]`)
        let total = simpleRateMultiplier(cases_on_hold_jq, price_per_case_jq)
        let total_cost_jq = total_credit_products.filter(`[data-product-id=${product_item_id}]`)
        // Trigger Change event for totalCost.js
        total_cost_jq.val(total.toFixed(2)).trigger("change")
        handle_case_credit_all()
    }

    cases_recovered_products.on("change", handle_case_credit_products)
    price_per_case_products.on("change", handle_case_credit_products)


    handle_case_credit_products(null)
    handle_case_credit_all(null)


}